import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Table from './Table';
import { GlobalContext } from '../../context/GlobalContext';
import GoBack from '../../components/GoBack';
const Sagigation = () => {
  
  const location = useLocation();
  const { API_URL } = useContext(GlobalContext);
  const searchParams = new URLSearchParams(location.search);
  // const { gsrcmd5 } = useParams();

  const fullGsrcmd5 = searchParams.get('gsrcmd5');
  // const gsrcmd5Parts = fullGsrcmd5 ? fullGsrcmd5.split("###") : [];

  console.log('fullGrscmd5', fullGsrcmd5)

  const [data, setData] = useState(false);


  useEffect(() => {
    const translatedText = async () => {
      try {
        const response = await axios.get(`${API_URL}/translation`);
        if (fullGsrcmd5) {
          const selectedData = response.data.filter((item) => fullGsrcmd5.includes(item.gsrcmd5));
          setData(selectedData);
        } else {
          setData([]); // Set an empty array if fullPsrcmd5 is null
        }
      } catch (error) {
        console.log(error);
        setData([]); // Set an empty array if API call fails or data is not an array
      }
    };
    translatedText();
    // eslint-disable-next-line
  }, [fullGsrcmd5]);

  return (
    <>

      <table className="container table table-bordered mt-2">
        <thead>
          <tr>
            <th>Source Text</th>
            <th>Group Hash</th>
            <th>Translation Text</th>
          </tr>
        </thead>
        <tbody>
          {data !== false ? (
            data.map((item, index) => (
              <tr key={index}>
                <td className="text-center" style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>{item.srctxt}</td>
                <td className="text-center" style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>{item.gsrcmd5}</td>
                <td className="text-center" style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}>{item.tgttxt}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={2}>Loading...</td>
            </tr>
          )}
        </tbody>
      </table>
      <div>
        <Table gsrcmd5Parts={fullGsrcmd5} />
      </div>
      {/* <SagigationTable
        handleCheckboxChange={handleCheckboxChange}
        data={transferData}
        filterData={filterData}
        selectedIds={selectedIds}
        handleEdit={handleEdit}
      />
      <div className='text-center'>
        <Button
          text="Update"
          handleSubmit={handleUpdateClick}
          tooltip="Data Update into translation table"
        />
      </div> */}

      {/*       
      <div>Sagigation here...</div>
      <div>psrcmd5 parts:</div>
      <ul>
        {transferData.map((myData, index) => (
          <div key={index}>
          <li>{myData.srctxt}</li>
          <li>{myData.srcmd5}</li></div>
        ))}
      </ul> */}

    <GoBack />
    </>
  );
};

export default Sagigation;
