import { useNavigate } from 'react-router-dom';

const GoBack = () => {
    const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // This will take you to the previous page
  };

    return (
        <>
            <div className="text-center mt-2" style={{ color: "#222" }}>
                <button className='btn btn-light border' onClick={goBack}>Go Back</button>
            </div>
        </>
    )
}

export default GoBack