import React from 'react'
import Pagination from './Pagination';

const PaginationModule = ({ totalPages, postsPerPage, setPostsPerPage, currentPage, setCurrentPage }) => {
    return (
        <>
            {/* Tasks Paginations */}
            <div className="mt-2 mx-20">
                <div className="d-flex justify-content-between">
                    <div className='ms-4'>
                        <label htmlFor="">Jump to</label>
                        <select
                            className='mx-2'
                            style={{ width: "50px", height: "30px" }}
                            value={postsPerPage}
                            onChange={(e) => {
                                setPostsPerPage(parseInt(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={300}>300</option>
                            <option value={400}>400</option>
                            <option value={500}>500</option>
                        </select>
                        <span>Page {currentPage} of {totalPages}</span>
                    </div>
                    <div className='mx-4 mb-5'>
                        <Pagination pages={totalPages} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default PaginationModule