import React from 'react'
import CollectionTable from './CollectionTable'

const Collection = () => {
  return (
    <>
    <CollectionTable />
    </>
  )
}

export default Collection
