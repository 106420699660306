import React, { useState, useEffect } from 'react';

function Pagination({ pages = 10, setCurrentPage }) {

  const numberOfPages = [];
  for (let i = 1; i <= pages; i++) {
    numberOfPages.push(i);
  }

  const [currentButton, setCurrentButton] = useState(1);
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  useEffect(() => {
    const calculatePaginationButtons = () => {
      let tempNumberOfPages = [];
      if (numberOfPages.length <= 7) {
        tempNumberOfPages = numberOfPages;
      } else if (currentButton <= 4) {
        tempNumberOfPages = [...numberOfPages.slice(0, 5), '...', numberOfPages.length];
      } else if (currentButton >= pages - 3) {
        tempNumberOfPages = [1, '...', ...numberOfPages.slice(pages - 5)];
      } else {
        tempNumberOfPages = [1, '...', currentButton - 1, currentButton, currentButton + 1, '...', numberOfPages.length];
      }
      setArrOfCurrButtons(tempNumberOfPages);
      setCurrentPage(currentButton);
    };

    calculatePaginationButtons();
 // eslint-disable-next-line
  }, [currentButton, setCurrentPage, pages, numberOfPages.length]);

  return (
    <div className="pagination-container" >
      
      <button
        
        className={`${currentButton === 1 ? 'disabled' : ''}`}
        onClick={() => setCurrentButton(1)}
      >
        First
      </button>
      <button
        
        className={`pagination-button ${currentButton === 1 ? 'disabled' : ''}`}
        onClick={() => setCurrentButton(prev => Math.max(prev - 1, 1))}
      >
        Prev
      </button>

      {arrOfCurrButtons.map((item, index) => (
        <button
          
          key={index}
          className={`pagination-button ${currentButton === item ? 'active' : ''}`}
          onClick={() => setCurrentButton(item)}
        >
          {item}
        </button>
      ))}

      <button
        
        className={`pagination-button ${currentButton === pages ? 'disabled' : ''}`}
        onClick={() => setCurrentButton(prev => Math.min(prev + 1, pages))}
      >
        Next
      </button>
      <button
        
        className={`${currentButton === numberOfPages.length ? 'disabled' : ''}`}
        onClick={() => setCurrentButton(numberOfPages.length)}
      >
        Last
      </button>
    </div>
  );
}

export default Pagination;
