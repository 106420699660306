import React, { useState } from "react";
import { BsSearch } from 'react-icons/bs';
import { VscRefresh } from 'react-icons/vsc';

const Search = ({ data, setFilterData }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    const result = data.filter((state) => {
      const srctxt = state.cleantxt || "";
      const id = state.id ? state.id.toString() : "";
      const domainName = state.domain || "";
      const multiPart = state.multipart || "";

      if (searchTerm === "") {
        return true;
      }

      const searchTerms = searchTerm.split(/\s*(?=\b(?:srctxt|domain|multipart):)/);

      return searchTerms.every((term) => {
        const trimmedTerm = term.trim().toLowerCase();

        if (trimmedTerm.startsWith("id:")) {
          const searchValues = trimmedTerm.slice(3).trim().split(",");
          return searchValues.includes(id);
        }

        if (trimmedTerm.startsWith("srctxt:")) {
          const searchValue = trimmedTerm.slice(7).trim();
          return srctxt.toLowerCase().includes(searchValue.toLowerCase());
        }

        if (trimmedTerm.startsWith("domain:")) {
          const searchValue = trimmedTerm.slice(7).trim();
          return domainName.toLowerCase().includes(searchValue.toLowerCase());
        }

        if (trimmedTerm.startsWith("multipart:")) {
          const searchValue = trimmedTerm.slice(10).trim();
          return multiPart.toLowerCase().includes(searchValue.toLowerCase());
        }

        return (
          srctxt.toLowerCase().includes(trimmedTerm) ||
          trimmedTerm.includes(trimmedTerm) ||
          domainName.toLowerCase().includes(trimmedTerm) ||
          multiPart.toLowerCase().includes(trimmedTerm)
        );
      });
    });
    setFilterData(result);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleSearch();
    }
  };

  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <div className="d-flex">
        <input
          type="text"
          className="form-control form-control-md"
          placeholder="Enter Search (srctxt: or multipart:)..."
          style={{ borderRadius: "25px 0 0 25px" }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button
          type="button"
          className="btn btn-light border"
          style={{ width: "50px", borderRadius: "0 25px 25px 0" }}
          onClick={handleSearch}
        >
          <BsSearch />
        </button>

        <div
          className="ms-2 border"
          onClick={refreshPage}
          style={{ width: '40px', height: '30px' }}
        >
          <VscRefresh style={{ width: '100%', height: '100%' }} className="refresh-icon" />
        </div>
      </div>
    </>
  );
};

export default Search;
