export default function formatDateString(originalDateString) {
    const dateParts = originalDateString.split(/[- :]/);
  
    const month = dateParts[1];
    const day = dateParts[2];
    const hour = dateParts[4];
    const minute = dateParts[5];
  
    const formattedString = `${month}-${day} ${hour}:${minute}`;
  
    return formattedString;
  }
  