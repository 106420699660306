import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Pagination from "../../components/Pagination";
import FetchTable from "../../components/FetchTable";
import DomainDropdown from "../../components/DomainDropdown";
import Search from "../../components/Search";
import { GlobalContext } from '../../context/GlobalContext';
import ModelView from "../collection/ModelView";
const Collection = () => {
  const { API_URL } = useContext(GlobalContext);

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  // eslint-disable-next-line
  const [showAlert, setShowAlert] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");
  // eslint-disable-next-line
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [transferData, setTransferData] = useState("");

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filterData.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(filterData.length / postsPerPage);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/translation`);
      setData(response.data);
      setFilterData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };
  const handleSelectAll = () => {
    const currentPageIds = currentPosts.map(item => item.id);
    const allSelected = currentPageIds.every(id => selectedIds.includes(id));

    if (allSelected) {
      setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
    } else {
      setSelectedIds([...selectedIds, ...currentPageIds]);
    }
  };
  const handleRemoveText = (indexToRemove) => {
    setSelectedIds((prevSelectedIds) => {
      // Create a new array without the item at the specified index
      const updatedSelectedIds = prevSelectedIds.filter((_, index) => index !== indexToRemove);
      return updatedSelectedIds;
    });
  };

  const handleSubmit = async () => {
    const selectedData = data.filter((item) => selectedIds.includes(item.id));
    console.log("selectedData: ", selectedData);

    try {
      // eslint-disable-next-line
      const response = await axios.post(
        `${API_URL}/translationToToDomain`,
        selectedData
      );
      setTransferData(response.data)
      console.log('message', transferData)

      const resetData = data.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      const resetFilterData = filterData.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      setData(resetData);
      setFilterData(resetFilterData);
      setSelectedIds([]);
      setShowAlert(true);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <div className="container">


        <div className="row justify-content">
          <div className="col-md-4 col-12 col-lg-4">
            <Search data={filterData} setFilterData={setFilterData} />
          </div>
          <div className="col-md-2 ms-2 px-0 py-0">
            <DomainDropdown
              data={filterData} // Use the filtered data here
              selectedDomain={selectedDomain}
              setSelectedDomain={setSelectedDomain}
              setFilterData={setFilterData}
            />
          </div>
          <div className="col-md-4">
            {selectedIds.length > 0 && (
              <div className="d-flex justify-content-end">
                <button className="btn btn-light border" onClick={handleSubmit}>Transfer</button>
                <button className="btn btn-light border" onClick={() => setSelectedIds([])}>Clear</button>
                <ModelView data={data} selectedIds={selectedIds} handleRemoveText={handleRemoveText} />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="">
        <SelectedData data={data} selectedIds={selectedIds} handleRemoveText={handleRemoveText} />
      </div> */}
      <div>
        {showAlert && (
          <pre className="p-3 mb-2 bg-alert-success text-white" role="alert">
            {transferData.message.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </pre>
        )}
      </div>
      {/* <div className="text-end me-5">
        {selectedIds.length > 0 && (
          <div>
            <button className="btn btn-light border" onClick={handleSubmit}>Transfer</button>
            <button className="btn btn-light border" onClick={() => setSelectedIds([])}>Clear</button>
          </div>
        )}
      </div> */}
      <div className="container-fluid mb-2">
        <FetchTable
          currentPosts={currentPosts}
          handleCheckboxChange={handleCheckboxChange}
          setFilterData={setFilterData}
          filterData={filterData}
          setData={setData}
          selectedIds={selectedIds}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
        />
      </div>
      <div className='text-md-right'>
        <div className='d-flex justify-content-between'>
          <div className='ml-5 ms-5'>
            <label htmlFor="">Jump to</label>
            <select
              className='mx-2'
              style={{ width: "50px", height: "30px" }}
              value={postsPerPage}
              onChange={(e) => {
                setPostsPerPage(parseInt(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={500}>500</option>
            </select>
            <span>Page {currentPage} of Pages {howManyPages}</span>
          </div>
          <div className='mx-5 mb-5'>
            <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Collection