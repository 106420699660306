import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
// import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import Nav from './Nav';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  

  return (
    <>
      <div className=''>
        <IconContext.Provider value={{ color: '#fff' }}>
          <div className='navbar mb-1 mx-2 box'>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
        </IconContext.Provider>
      </div>
      <Nav showSidebar={showSidebar} sidebar={sidebar} />
  
    </>
  );
}

export default Navbar;