import React, { useState, useEffect } from 'react';
import { GlobalContext } from './GlobalContext';
import axios from "axios";

const GlobalProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [DomainData, setDomainData] = useState([]);
  const [filterDomainData, setFilterDomainData] = useState([]);

    // Accessing the API URL from environment variables
    const API_URL = process.env.REACT_APP_API_URL;
    
  useEffect(() => {
    const CollectionFetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/collectionTableData`);
        setData(response.data);
        setFilterData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    CollectionFetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const DomainFetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/DomainTableData`);
        setDomainData(response.data);
        setFilterDomainData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    DomainFetchData();
    // eslint-disable-next-line
  }, []);

  const contextValue = { API_URL, data, setData, filterData, setFilterData, DomainData, setDomainData, filterDomainData, setFilterDomainData };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
