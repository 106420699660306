import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { BsArrowDownUp } from "react-icons/bs";
import { GlobalContext } from '../../context/GlobalContext';
import axios from "axios";

const Table = ({ gsrcmd5Parts }) => {
  const { API_URL } = useContext(GlobalContext);

  const [data, setData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [filterDomainData, setFilterDomainData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  useEffect(() => {
    const DomainFetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/ContentSagigation`);
        setDomainData(response.data);
        setFilterDomainData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    DomainFetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getTableData = async () => {
      try {
        // const response = await axios.get(`${API_URL}/sagigation`);
        const selectedData = domainData.filter((item) => gsrcmd5Parts.includes(item.gsrcmd5));
        setData(selectedData);
        console.log("sagigationupdatetbl", data);
      } catch (error) {
        console.log(error);
      }
    };
    getTableData();
 // eslint-disable-next-line
  }, [gsrcmd5Parts, domainData]);

  const handleEdit = (item) => {
    setEditingItem(item);
  };

  const handleSave = async (item) => {
    try {
      const res = await fetch(`${API_URL}/update/v1/${item.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      });
      const updatedData = await res.json();
      console.log(updatedData);
      setEditingItem(null);
      // Update the data array with the updated item
      setData((prevData) =>
        prevData.map((dataItem) =>
          dataItem.id === item.id ? { ...dataItem, ...item } : dataItem
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const sortedData = useMemo(() => {
    const sortedArray = [...data];

    if (sortColumn !== "") {
      sortedArray.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortColumn === "lastmodified") {
          // Convert timestamps to Date objects for comparison
          const dateA = new Date(valueA).getTime();
          const dateB = new Date(valueB).getTime();

          if (dateA < dateB) return sortDirection === "asc" ? -1 : 1;
          if (dateA > dateB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        } else {
          if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
          if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
          return 0;
        }
      });
    }

    return sortedArray;
  }, [data, sortColumn, sortDirection]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Group data by part
  const parts = {};
  data.forEach(item => {
    if (!parts[item.part]) {
      parts[item.part] = [];
    }
    parts[item.part].push(item.tgttxt);
  });

  // Sort parts in ascending order
  const sortedParts = Object.keys(parts).sort((a, b) => a - b);

  // Generate joined value in ascending order
  const joinedValue = sortedParts.map(part => parts[part].join(' ')).join(' >>>');

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      // Close edit mode when "Escape" key is pressed
      setEditingItem(null);
    } else if (event.key === 'Enter' && event.ctrlKey) {
      // Save changes when "Ctrl + Enter" is pressed
      handleSave(editingItem);
    }
  };
  const textareaRef = useRef(null);
  useEffect(() => {
    const handleDocumentKeyDown = (event) => {
      if (event.key === 'Escape' && textareaRef.current && !textareaRef.current.contains(event.target)) {
        // Close edit mode when "Escape" key is pressed outside the textarea
        setEditingItem(null); // or setEditingItem(initialEditingItemValue) if you have an initial value
      }
    };

    document.addEventListener('keydown', handleDocumentKeyDown);

    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, []);

  return (
    <>

      <table id="" className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center" style={{ width: "4%" }} scope="col" onClick={() => handleSort("id")}>
              id<b><BsArrowDownUp /></b>
            </th>
            <th className="" style={{ width: "10%" }} scope="col">
              Source Text
            </th>
            <th className="" style={{ width: "10%" }} scope="col">
              Translation Text
            </th>
            <th className="text-center" style={{ width: "10%" }} scope="col">
              Group Hash
            </th>
            <th className="text-center" style={{ width: "5%" }} scope="col">
              multipart
            </th>
            <th className="text-center" style={{ width: "5%" }} scope="col" onClick={() => handleSort("part")}>
              part{" "}<b><BsArrowDownUp /></b>
            </th>
            <th className="text-center" style={{ width: "5%" }} scope="col" onClick={() => handleSort("seq")}>
              sequence{" "}<b><BsArrowDownUp /></b>
            </th>
            <th className="text-center" style={{ width: "6%" }} scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((myData, index) => (
            <tr className="" key={index}>
              <th className="text-center" scope="row">{myData.id}</th>
              <td>{myData.srctxt}</td>
              {editingItem && editingItem.id === myData.id ? (
                <td>
                  <textarea
                    ref={textareaRef}
                    className="form-control"
                    rows={6}
                    type="text"
                    value={editingItem.tgttxt}
                    onKeyDown={handleKeyDown}
                    onChange={(event) => {
                      setEditingItem({
                        ...editingItem,
                        tgttxt: event.target.value,
                      });
                    }}
                  />
                </td>
              ) : (
                <td onClick={() => handleEdit(myData)}>{myData.tgttxt || "---"}</td>
              )}
              <td className="text-center"
              style={{ 'whiteSpace': 'pre-wrap', wordWrap: "break-word", overflow: "hidden" }}
              >{myData.gsrcmd5}</td>
              <td className="text-center">{myData.multipart}</td>
              <td className="text-center">{myData.part}</td>
              <td className="text-center">{myData.seq}</td>
              <td className="text-center">
                {editingItem && editingItem.id === myData.id ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleSave(editingItem)}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleEdit(myData)}
                  >
                    Edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className='text-center'>
        <div className='col-md-8 mx-auto'>

          <textarea
          style={{backgroundColor: "#f2f2f2"}}
            className='form-control'
            name="text"
            value={joinedValue || "--"}
            readOnly
            id=""
            rows="5"
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default Table;
