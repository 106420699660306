import "./Popup.css";

function Popup({ setOpenModal, message }) {

  return (
    <div className="popUpBackground">
      <div className="modalContainerPopup">
        <div className="titleCloseBtn11 d-flex">
          <h4 className="mr-2 mt-2">{message}</h4>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>

        </div>
      </div></div>

  );
}

export default Popup;
