import React, { useState } from "react";
import Modal from "../../pages/model/Modal";

function ModelView({data, selectedIds, handleRemoveText}) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="App">
      <button
        className="btn btn-light border"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        View
      </button>

      {modalOpen && <Modal setOpenModal={setModalOpen} data={data} selectedIds={selectedIds} handleRemoveText={handleRemoveText} />}
    </div>
  );
}

export default ModelView;