import Title from './components/Title';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './sidebar/Navbar';
import Collection from './pages/collection/Collection';
import Contents from './pages/contents/Contents';
import Translation from './pages/translation/Translation'
import Sagigation from './pages/sagigation/Sagigation';
import GlobalProvider from './context/GlobalProvider';
import Locate from './pages/locate/Locate';
import Search from './pages/search/Search';

const App = () => {
  return (
    <>
      <GlobalProvider>
        <BrowserRouter>
          <div className='container-fluid'>
            <div className='d-flex text-center'>
              <Navbar />
              <div className='w-100'>
                <Title />
              </div>
            </div>
            <Routes>
              <Route path="/" element={<Collection />} />
              <Route path="/contents" element={<Contents />} />
              <Route path="/translation" element={<Translation />} />
              <Route path="/segregate" element={<Sagigation />} />
              <Route path="/locate" element={<Locate />} />
              <Route path="/search" element={<Search />} />
            </Routes>
          </div>
        </BrowserRouter>
      </GlobalProvider>
    </>
  );
};

export default App;







