import ContentsTable from "./ContentsTable"

const Contents = () => {
  return (
    <>
    <ContentsTable />
    </>
  )
}

export default Contents