import "./Modal.css";
import { TfiClose } from "react-icons/tfi";

function Modal({ setOpenModal, data, selectedIds, handleRemoveText }) {

  const selectedTexts = data
    .filter((item) => selectedIds.includes(item.id))
    .map((item) => item);

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn d-flex">
        <h4 className="mr-2 mt-2">Selected rows ready for transfer...</h4>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
          X
          </button>
          
        </div>
        <div className="title">
          <div className="container">
            {selectedTexts.map((myData, index) => (
              <div className="d-flex" key={myData.id}> {/* Assign a unique key */}
                <div className="me-1">({index + 1}) - </div>
                {/* <div className="me-5"> ({myData.id}) - </div> */}
                <div>({myData.srctxt})</div>
                <div className="ms-2" onClick={() => handleRemoveText(index)} style={{ cursor: 'pointer' }}>
                  <TfiClose />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
