import SearchTable from './SearchTable'

const Search = () => {


  return (
    <>
      <SearchTable />
    </>
  )
}

export default Search
