import LocateTable from './LocateTable'

const Locate = () => {


  return (
    <>
      <LocateTable />
    </>
  )
}

export default Locate
