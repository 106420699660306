import React, { useState, useEffect, useContext } from "react";
import "../../style.css";
import axios from "axios";
import Pagination from "../../components/Pagination";
// import Search from "../../components/Search";
import CollectionFetchTable from "./CollectionFetchTable";
// import DomainDropdown from "../../components/DomainDropdown";
// import { Link } from "react-router-dom";
import { GlobalContext } from '../../context/GlobalContext';
import ModelView from "./ModelView";
import Modal from "../model/Modal";
import Popup from "../popup/Popup"
// import Alert from "./Alert";
import PaginationModule from '../../pagination/PaginationModule';

const Table = () => {
  const { API_URL } = useContext(GlobalContext);

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [transferData, setTransferData] = useState("");
  // eslint-disable-next-line
  const [selectAll, setSelectAll] = useState(false);
  const [message, setMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [domainURL, setDomainURL] = useState([]);
  const [URLs, setURLs] = useState([]);
  const [selectedURL, setSelectedURL] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // Get current posts
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filterData.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(filterData.length / postsPerPage);

  useEffect(() => {
    const Domain = async () => {
      try {
        const response = await axios.get(`${API_URL}/collectionsDomainURL`);
        setDomainURL(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    Domain();
    // eslint-disable-next-line
  }, []);

  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setSelectedDomain(selectedDomain);
  };

  const handleURLChange = (event) => {
    const selectedURL = event.target.value;
    setSelectedURL(selectedURL);
  };


  useEffect(() => {
    // Calculate the unique URLs for the selected domain
    const uniqueURLs = Array.from(
      new Set(
        domainURL
          .filter((state) => state.domain === selectedDomain)
          .map((state) => state.cur_url)
          .filter((url) => url && url.trim() !== "")
      )
    ).sort();

    setURLs(uniqueURLs);
  }, [data, selectedDomain, domainURL]);


  const handleSearch = async () => {

    const formData = {
      script: selectedURL,
      domain: selectedDomain,
    };

    setAlertMessage('collection data searching....');
    setTimeout(() => {
      setAlertMessage('');
    }, 8000);

    try {
      const response = await axios.post(`${API_URL}/collections`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setData(response.data);
        setFilterData(response.data);
        setCurrentPage(1); // Reset the current page to 1
        console.log("value return: ", data)
      } else {
        console.error('Error searching database');
      }
    } catch (error) {
      console.error('Error searching database:', error);
    }
  };

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSubmit = async () => {
    setMessage(true);
    setShowAlert(false);

    const selectedData = data.filter((item) => selectedIds.includes(item.id));
    console.log("selectedData: ", selectedData);

    try {
      // eslint-disable-next-line
      const response = await axios.post(
        `${API_URL}/transferToDomain`,
        selectedData
      );
      setTransferData(response.data)
      console.log('message', transferData)

      const resetData = data.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });

      const resetFilterData = filterData.map((item) => {
        if (selectedIds.includes(item.id)) {
          return {
            ...item,
            checked: false,
          };
        }
        return item;
      });
      setMessage(false);
      setData(resetData);
      setFilterData(resetFilterData);
      setSelectedIds([]);
      setShowAlert(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAll = () => {
    const currentPageIds = currentPosts.map(item => item.id);
    const allSelected = currentPageIds.every(id => selectedIds.includes(id));

    if (allSelected) {
      setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
    } else {
      setSelectedIds([...selectedIds, ...currentPageIds]);
    }
  };

  const checkAll = () => {
    const confirmCheckAll = window.confirm("Are you sure you want to check all data for transfer?");

    if (confirmCheckAll) {
      const currentPageIds = filterData.map(item => item.id);
      const allSelected = currentPageIds.every(id => selectedIds.includes(id));

      if (allSelected) {
        setSelectedIds(selectedIds.filter(id => !currentPageIds.includes(id)));
      } else {
        setSelectedIds([...selectedIds, ...currentPageIds]);
      }
    }
  };

  const handleRemoveText = (indexToRemove) => {
    setSelectedIds((prevSelectedIds) => {
      // Create a new array without the item at the specified index
      const updatedSelectedIds = prevSelectedIds.filter((_, index) => index !== indexToRemove);
      return updatedSelectedIds;
    });
  };


  return (
    <>
      {alertMessage && (
        <Popup setOpenModal={setAlertMessage} message="collection data searching...." />)}

      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <select className="form-control" value={selectedDomain} onChange={handleDomainChange}>
                <option value="">Select Domain</option>
                {Array.from(new Set(domainURL.map((state) => state.domain)))
                  .filter((domain) => domain && domain.trim() !== "")
                  .sort()
                  .map((domain, index) => (
                    <option key={index} value={domain}>
                      {domain}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3">
              <select className="form-control" value={selectedURL} onChange={handleURLChange}>
                <option value="">Select URL</option>
                <option value="all">Select All</option> {/* Add this option */}
                {URLs.map((url, index) => (
                  <option key={index} value={url}>
                    {url}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-1">
              <button className="btn btn-light border" onClick={handleSearch}>
                Search
              </button>
            </div>
            <div className="d-flex col-md-3">
              {selectedDomain ?
                <button className="btn btn-light border" onClick={checkAll}>Check All</button>
                : ""}
              <div>
                {selectedIds.length > 0 && (
                  <button className="btn btn-light border text-danger ms-1"
                    onClick={() => {
                      setModalOpen(true);
                    }}>
                    Selected: {selectedIds.length}
                  </button>

                )}
                {modalOpen && <Modal setOpenModal={setModalOpen} data={data} selectedIds={selectedIds} handleRemoveText={handleRemoveText} />}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedIds.length > 0 && (
        <div className="container d-flex justify-content-end">
          <button className="btn btn-light border" onClick={handleSubmit}>Transfer</button>
          <button className="btn btn-light border" onClick={() => setSelectedIds([])}>Clear</button>
          <ModelView data={data} selectedIds={selectedIds} handleRemoveText={handleRemoveText} />
        </div>
      )}
      <div className="">
        {message && (
          <Popup setOpenModal={setMessage} message="Data processing, please wait..." />)}
      </div>
      <div>
        {showAlert && (
          <pre className="p-3 mb-2 bg-alert-success text-white" role="alert">
            {transferData.message.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </pre>
        )}
      </div>
      <div className="container-fluid mb-2">
        <CollectionFetchTable
          currentPosts={currentPosts}
          handleCheckboxChange={handleCheckboxChange}
          selectedIds={selectedIds}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
        />
      </div>

      <div>
        <PaginationModule totalPages={howManyPages} postsPerPage={postsPerPage} setPostsPerPage={setPostsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      
    </>
  );
};

export default Table;
